require('bc-image-loader');

const $ = require('jquery');
global.jQuery = $;

// Initialize all global stuff with data from back-end
const { initGlobals } = require('../../common/js/commonSetup');
initGlobals();

require('../../../../shared/lib/handlebarsHelpersTime')();
require('../../common/js/sorting');
const { videoFinisher } = require('../../common/js/videofinisher');
const BcVideoPlayer = require('./bc-videoplayer');
const utils = require('../../common/js/utils');
const Cookies = require('js-cookie');

const MOBILE = 1;
const DESKTOP = 1024;
const DESKTOP_MD = 1170;
const DESKTOP_LG = 1440;

/*-------------------------------------------------------------*/
/* GLOBAL */
class Main {
  constructor () {
    // used to cache .hbs templates
    this.bcTemplatesCache = {};
  }

  determineDimensions () {
    const width = window && window.innerWidth ? window.innerWidth : $('body').innerWidth();

    let breakpoint = MOBILE;
    if (width >= DESKTOP && width < DESKTOP_MD) {
      breakpoint = DESKTOP;
    } else if (width >= DESKTOP_MD && width < DESKTOP_LG) {
      breakpoint = DESKTOP_MD;
    } else if (width >= DESKTOP_LG) {
      breakpoint = DESKTOP_LG;
    }

    return {
      width: width,
      height: $(window).height(),
      breakpoint: breakpoint,
    };
  }

  loadTemplate (url, callback) {
    if (this.bcTemplatesCache[url]) {
      return callback(this.bcTemplatesCache[url]);
    }

    $.ajax({
      url: url,
      success: (contents) => {
        this.bcTemplatesCache[url] = window.bcGallery.Handlebars.compile(contents);
        callback(this.bcTemplatesCache[url]);
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  parseQueryParam (field, urlString) {
    const url = urlString ? urlString : window.location.href;
    const regex = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
    const matches = regex.exec(url);
    return matches ? matches[1] : null;
  }

  adjustRelatedLinks () {
    const $relatedLink = $('#mod-now-playing-related-link');
    const $relatedLinkAnchor = $relatedLink.find('.related-link a');
    const $relatedLinkCollision = $('#mod-now-playing-social .social-share');

    if ($relatedLinkAnchor.length <= 0) {
      return;
    }

    $relatedLink.removeClass('collided');

    const dimensions = this.determineDimensions();
    if (dimensions.breakpoint === MOBILE) {
      return;
    }

    if ($relatedLinkAnchor.offset().left + $relatedLinkAnchor.width() > ($relatedLinkCollision.offset().left - 10)) {
      $relatedLink.addClass('collided');
    }
  }

  onNextVideo () {
    if ($('body').hasClass('page-home')) {
      return;
    }
    utils.nextVideo();
  }
}

/*-------------------------------------------------------------*/
/* CATEGORIES */
class Categories {
  constructor () {
    // used to store original descriptions text to restore ellipsis
    this.descriptionsCache = {};
    this.descriptionsTimeout = 0;
  }

  init () {
    $('.mod-videos__item .mod-videos__description').each((index, element) => {
      const $description = $(element);
      this.descriptionsCache[$description.attr('id')] = $description.html();
    });

    $('.mod-videos__categories__main-sub').on('mouseover', function () {
      const $sub = $(this);

      $sub.addClass('open');
      $sub.find('.mod-videos__categories__sub').show();
      $sub.find('.mod-videos__categories__sub').width($sub.width());
      $sub.find('.menu-arrow-down').toggleClass('fa-caret-down fa-caret-up');
    });

    $('.mod-videos__categories__main-sub').on('mouseout', function () {
      const $sub = $(this);

      $sub.removeClass('open');
      $sub.find('.mod-videos__categories__sub').hide();
      $sub.find('.menu-arrow-down').toggleClass('fa-caret-up fa-caret-down');
    });

    $('#mod-categories-see-all-link').on('click', function (e) {
      e.preventDefault();

      const dimensions = main.determineDimensions();
      if (dimensions.breakpoint === MOBILE) {
        nav.setHamburgerOpen(true);
      } else {
        nav.setCategoriesDropdownOpen(true);
      }

      $('html,body').animate({
        scrollTop: 0,
      },{
        duration: 1200,
        easing: 'swing',
      });
    });
  }
}

const MODE_PLAYBACK = 'playback';

/*-------------------------------------------------------------*/
/* PLAYLIST */
class Playlist {
  constructor () {
    this.mode = null;

    // functionality disabled when set to true
    this.isLoading = false;

    // how many videos are in the playlist?
    this.totalVideoCount = 0;

    // abstraction layer for "smart" versus "perform" player differences
    this.bcVideoPlayer = null;

    // currently loaded / playing video
    this.currentVideo = null;

    // used for populating sharing content
    this.currentSocialBaseUrl = null;
    this.currentSite = null;
    this.currentCategory = null;

    // the most recent video id that last fired a 'started' event
    this.previousStartedVideoId = null;

    // store calls to the api in this cache to prevent multiple
    // calls for the same video JSON
    this.cachedVideoJson = {};
  }

  init () {
    // mode affects functionality throughout the playlist
    this.mode = $('#mod-playlist').data('mode');

    // discoveryData.isAutoPlay is set globally by layout and is initialized
    // to the value of the autoplay next setting - changing the playlist
    // manually will override this initialization but does not change
    // the actual setting
    if (Cookies.get('BCDISCOVERY_AUTOPLAY')) {
      this.setIsAutoplay(Cookies.get('BCDISCOVERY_AUTOPLAY'));
    } else {
      this.setIsAutoplay(window.discoveryData.isAutoPlay);
    }

    // discoveryData set by layout and window objects set by shared JS
    this.currentSocialBaseUrl = window.discoveryData.socialBaseUrl;
    this.currentCategory = window.category;
    this.currentSite = window.site;

    // how many videos are in the playlist
    this.totalVideoCount = parseInt($('.mod-playlist__next-items-wrapper').data('count'));

    // if we're in 'single' mode, we don't need to show the placeholder so
    // immediately hide it
    if (this.mode !== MODE_PLAYBACK) {
      $('#mod-now-playing-video').addClass('is-playing');
    }

    // initialize interaction listeners
    $('.mod-playlist').on('click', '.mod-playlist__next-item-link', e => {
      e.preventDefault();
      this.playVideo($(e.currentTarget).closest('.mod-playlist__next-item').data('video-id'));
    });

    $('.mod-now-playing__placeholder-hotspot').on('click', e => {
      e.preventDefault();
      this.playVideo($(e.currentTarget).data('video-id'));
    });

    $('.icon-container').on('click', e => {
      e.preventDefault();
      this.setIsAutoplay(!this.isAutoPlay());
    });

    // listen for image loads which requires potential resize
    $('.mod-playlist').find('img').each((index, element) => {
      $(element).on('load', () => {
        this.adjustSiteWrapperHeight();
      });
    });

    // no-op the default next video function since we're handling it via the player abstraction
    videoFinisher.onNextVideo(function () {
      main.onNextVideo();
    });

    // contruct abstract player but do not initialize until template is loaded
    this.bcVideoPlayer = new BcVideoPlayer();
    this.bcVideoPlayer.addEventListener(BcVideoPlayer.EVENT_TEMPLATE_LOADED, () => {
      this.onPlayerTemplateLoaded();
    });
    this.bcVideoPlayer.addEventListener(BcVideoPlayer.EVENT_METADATA_LOADED, () => {
      this.onPlayerMetadataLoaded();
    });
    this.bcVideoPlayer.addEventListener(BcVideoPlayer.EVENT_VIDEO_STARTED, () => {
      this.onPlayerVideoStarted();
    });
    this.bcVideoPlayer.addEventListener(BcVideoPlayer.EVENT_VIDEO_ENDED, () => {
      this.onPlayerVideoEnded();
    });
    this.bcVideoPlayer.addEventListener(BcVideoPlayer.EVENT_VIDEO_ERROR, (player, data) => {
      this.onPlayerVideoError(player, data);
    });

    // if we have a starting video id and we're in playback mode,
    // immediate play that video (utils comes from shared JS)
    const startingVideoId = main.parseQueryParam('startPlaylistAt');
    if (startingVideoId && this.mode === MODE_PLAYBACK) {
      this.playVideo(startingVideoId);
    }
  }

  playVideo (videoId) {
    // no need to do anything if we're already playing this video
    if (!videoId || this.currentVideo && this.currentVideo.id === videoId.toString()) {
      return;
    }

    // dimensions check trumps all other interactions
    // if we are at any widths smaller than desktop, just go to the video detail
    const playlistItemUrl = $('#mod-playlist-next-item-' + videoId).data('video-url');
    const dimensions = main.determineDimensions();
    if (dimensions.breakpoint < DESKTOP) {
      document.location.href = utils.urlWithDevice(playlistItemUrl);
      return;
    }

    // if we're not in playback mode then we want to proceed
    // back to the homepage for playback
    if (this.mode !== MODE_PLAYBACK) {
      this.gotoHomepageAndPlay(videoId);
      return;
    }

    // otherwise, "lock" against interaction before proceeding
    // with playback loading
    if (this.isLoading) { return; }
    this.setIsLoading(true);

    // check cache first before hitting the API
    if (this.cachedVideoJson[videoId]) {
      this.onVideoApiDidLoad(this.cachedVideoJson[videoId]);
      return;
    }

    // if not found in cache, use the API to load the requested
    // video information before proceeding
    $.ajax(window.baseUrl + '/api/video/' + videoId, {
      dataType: 'json',
      success: (data) => { this.onVideoApiDidLoad(data); },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  onVideoApiDidLoad (data) {
    // rough validity check for video information
    const videoJson = data && data.result && data.result.id ? data.result : null;
    if (!videoJson) {
      console.warn('BCDISCOVERY - playlist video api load error');
      return;
    }

    // update the cache before proceeding
    this.cachedVideoJson[data.result.id] = data;
    this.previousVideo = this.currentVideo;
    this.currentVideo = videoJson;

    // load the player template if need be
    if (!this.bcVideoPlayer.isTemplateLoaded()) {
      main.loadTemplate(utils.getPlayerTemplateUrl(), (template) => {
        // inject template HTML
        $('#mod-now-playing-video').html(template({
          url: window.location.href,
          video: videoJson,
          isSecure: window.location.protocol === 'https:',
          'user-agent': window.navigator.userAgent,
          subPath: window.subPath,
          player: window.bc_gallery.player,
          site: window.site,
        }));

        // ready to initialize abstraction player after HTML is injected
        this.bcVideoPlayer.init();
      });

      // return here b/c wait for the player to be ready
      return;
    }

    // if the player was ready, we can go right into playing
    // proceeds to onPlayerVideoStarted once player starts from here
    this.bcVideoPlayer.playVideo(this.currentVideo);
  }

  onPlayerTemplateLoaded () {
    // start playing on a timeout otherwise this doesn't actually trigger
    // proceeds to onPlayerVideoStarted once player starts from here
    // we call 'onPlayerVideoStarted' anyway in case we're on a device
    // that requires user interaction before playback
    setTimeout(() => {
      this.bcVideoPlayer.play();
      this.onPlayerVideoStarted();
    }, 100);
  }

  onPlayerMetadataLoaded () {
    // start playing on a timeout otherwise this doesn't actually trigger
    // proceeds to onPlayerVideoStarted once player starts from here,
    // we call 'onPlayerVideoStarted' anyway in case we're on a device
    // that requires user interaction before playback
    setTimeout(() => {
      this.bcVideoPlayer.play();
      this.onPlayerVideoStarted();
    }, 100);
  }

  onPlayerVideoStarted () {
    // only perform this the first time a video changes ('started' happens
    // multiple times during playback, for e.g. if video is scrubbed)
    const startingVideoId = this.currentVideo.id;

    if (startingVideoId !== this.previousStartedVideoId) {
      this.setNowPlayingVideo(this.currentVideo);
      this.setNowPlayingSocial(this.currentVideo);
      this.setNowPlayingRelated(this.currentVideo);
      this.setIsLoading(false);
      this.setPlaylistPosition(this.currentVideo.id);
      this.scrollBackToTop();

      // uses CSS to "hide" the placeholder image and play icon
      // we don't completely remove the placeholder b/c the image itself
      // is what gives the player width/height via CSS
      $('#mod-now-playing-video').addClass('is-playing');
    }

    // the starting is is now the "new previous" post checks above
    this.previousStartedVideoId = startingVideoId;
  }

  onPlayerVideoEnded () {
    // if we're not autoplaying, there is nothing to do, or if we're in the WYSIWYG
    if (!this.isAutoPlay() || $('body').hasClass('bc-studio')) {
      return;
    }

    // ignore video end on any mode but playback
    if (this.mode !== MODE_PLAYBACK) {
      return;
    }

    // otherwise, we're in playback
    const $currentItem = $('#mod-playlist-next-item-' + this.currentVideo.id);
    const currentItemIndex = parseInt($currentItem.data('index'));
    let nextItemIndex = currentItemIndex + 1;

    // this has the effect of "carousel'ing" around back to the first video
    // when auto-playing
    if (nextItemIndex >= this.totalVideoCount) {
      nextItemIndex = 0;
    }

    // select the next video by it's id
    const $nextItem = $('.mod-playlist__next-item[data-index="' + nextItemIndex + '"]');
    const nextItemId = $nextItem.data('video-id');

    // go through the motions of playing the next video
    this.playVideo(nextItemId);
  }

  onPlayerVideoError (player, data) {
    // this is a temporary workaround the "d.off" error
    setTimeout(() => {
      this.setIsLoading(false);
      this.playVideo(data.video.id);
    }, 500);
  }

  setIsLoading (isLoading) {
    this.isLoading = isLoading;

    const $curtain = $('.mod-playlist__loading-curtain');
    const $spinner = $('.mod-playlist__loading-spinner');

    if (this.isLoading) {
      $curtain.stop().fadeIn(300);
      $spinner.stop().fadeIn(300);
    } else {
      $curtain.stop().fadeOut(100);
      $spinner.stop().fadeOut(100);
    }
  }

  setIsAutoplay (isAutoPlay) {
    // value may come from a cookie in which case it'll be a string
    // be we want a boolean value
    this._isAutoPlay = typeof isAutoPlay === 'boolean' ? isAutoPlay : isAutoPlay === 'true';

    // also remember in a cookie (used for initialization)
    Cookies.set('BCDISCOVERY_AUTOPLAY', this._isAutoPlay, { expires: 10 });

    const $autoplay = $('.mod-playlist__autoplay');
    const $autoplayStatus = $('.mod-playlist__status');

    if (this._isAutoPlay) {
      $autoplay.addClass('autoplay-on').removeClass('autoplay-off');
      $autoplayStatus.html(window.translations.autoplayon);
    } else {
      $autoplay.addClass('autoplay-off').removeClass('autoplay-on');
      $autoplayStatus.html(window.translations.autoplayoff);
    }
  }

  isAutoPlay () {
    return this._isAutoPlay;
  }

  setPlaylistPosition (videoId) {
    // do not re-order outside of playback mode
    if (this.mode !== MODE_PLAYBACK) {
      return;
    }

    const $playlist = $('.mod-playlist__next-items-wrapper');
    const $targetItem = $('#mod-playlist-next-item-' + videoId);
    const $previousItems = $targetItem.prevAll('.mod-playlist__next-item');

    const targetItemIndex = $targetItem.data('index');
    const reloadFirst = [ ];
    const reloadSecond = [ ];
    $.each($previousItems, function (index, item) {
      const $item = $(item);
      const itemIndex = $item.data('index');

      if (itemIndex > targetItemIndex) {
        reloadSecond.push($item);
      } else {
        reloadFirst.push($item);
      }
    });

    // this is equivalent to rearranging the entire list which is not necessary
    if ((reloadFirst.length + reloadSecond.length) === (this.totalVideoCount - 1)) {
      return;
    }

    $targetItem.stop().slideUp(300);
    $previousItems.stop().slideUp(300);

    setTimeout(function () {
      $.each([reloadSecond, reloadFirst], function (listIndex, items) {
        items.reverse();
        $.each(items, function (itemIndex, item) {
          const $item = $(item);
          $item.stop().hide().remove().appendTo($playlist).slideDown();
        });
      });

      $targetItem.stop().hide().remove().appendTo($playlist).slideDown();
    }, 400);
  }

  setNowPlayingVideo (video) {
    main.loadTemplate(window.bcGallery.getTemplatePath('/sites/discovery/partials/playlist-viewing-item.hbs'), function (template) {
      const html = template({
        video: video,
        imageTranscoder: window.BCLS ? window.BCLS.imageTranscoder : '',
        translations: window.translations,
        subPath: window.subPath,
      });

      const $content = $('#mod-playlist-viewing-content');
      $content.html(html);
      $content.addClass('is-playing');// make "playing" bar appear if not already
    });
  }

  setNowPlayingSocial (video) {
    main.loadTemplate(window.bcGallery.getTemplatePath('/sites/discovery/partials/now-playing-social.hbs'), (template) => {
      const html = template({
        video: video,
        site: this.currentSite,
        category: this.currentCategory,
        baseUrl: this.currentSocialBaseUrl,
        translations: window.translations,
        subPath: window.subPath,
      });

      $('#mod-now-playing-social').html(html);
    });
  }

  setNowPlayingRelated (video) {
    main.loadTemplate(window.bcGallery.getTemplatePath('/components/relatedLinks.hbs'), (template) => {
      const html = template({
        video: video,
        site: this.currentSite,
        translations: window.translations,
        subPath: window.subPath,
        useIcon: true,
      });

      $('#mod-now-playing-related-link').html(html);

      main.adjustRelatedLinks();
    });
  }

  scrollBackToTop () {
    $('html, body').animate({
      scrollTop: $('#mod-now-playing-video').offset().top,
    },{
      duration: 300,
    });
  }

  gotoHomepageAndPlay (videoId) {
    // window.baseUrl is set by shared JS, we use this instead of social base URL
    // so that we redirect according to preview or not (social ignores preview)
    let baseUrl = window.baseUrl;
    if (!baseUrl || baseUrl.length === 0) {
      baseUrl = '/';
    }

    document.location.href = utils.urlWithDevice(baseUrl + '?startPlaylistAt=' + encodeURIComponent(videoId));
  }

  adjustSiteWrapperHeight () {
    const dimensions = main.determineDimensions();
    const $siteWrapper = $('.site-wrapper');
    const $columnRight = $('.mod-column-right');

    $columnRight.height('auto');
    if (dimensions.breakpoint >= DESKTOP) {
      $columnRight.height($siteWrapper.height());
    }
  }

  adjustOverscroll () {
    // we only need to perform this if a custom footer is present
    const $customFooter = $('.custom-footer-container');
    if ($customFooter.length <= 0) {
      return;
    }

    // bring "overscroll" gradient up from the bottom according to the height of
    // a custom footer since the "overscroll" gradient is fixed position
    const scrollThreshold = ($(document).innerHeight() - $(window).height()) - $customFooter.height();
    const windowScrollTop = $(window).scrollTop();
    let overscrollBottom = windowScrollTop - scrollThreshold;
    if (overscrollBottom < 0) {
      overscrollBottom = 0;
    }

    $('.mod-playlist__overscroll').css('bottom', overscrollBottom);
  }
}

/*-------------------------------------------------------------*/
/* NAV */
class Nav {
  constructor () {
    // is the playlist drawer open?
    this.isDrawerOpen = false;

    // is the hamburger open?
    this.isHamburgerOpen = false;

    // is the categories dropdown open?
    this.isCategoriesDropdownOpen = false;
  }

  init () {
    $('.site-search__button').on('click', (e) => {
      e.preventDefault();
      this.submitSearch();
    });

    $('#site-search-input').on('keyup', (e) => {
      if (e && e.keyCode === 13) {// enter
        if (e.originalEvent.isComposing) { // don't execute search if keyboard is composing input
          return;
        }
        this.submitSearch();
      }
    });

    $('.drawer-trigger').on('click', (e) => {
      e.preventDefault();
      this.setDrawerOpen(!this.isDrawerOpen);
    });

    $('.mod-playlist-drawer').on('click', (e) => {
      // ensure that clicks outside of this close the drawer
      if (e.target === this) {
        this.setDrawerOpen(false);
      }
    });

    $('.site-nav__hamburger').on('click', (e) => {
      e.preventDefault();
      this.setHamburgerOpen(!this.isHamburgerOpen);
    });

    $('.site-nav__collections-item--toggle').on('click', (e) => {
      e.preventDefault();
      this.setCategoriesDropdownOpen(!this.isCategoriesDropdownOpen);
    });
  }

  setDrawerOpen (isOpen) {
    if (isOpen === this.isDrawerOpen) {
      return;
    }

    this.isDrawerOpen = isOpen;

    // adjust widths prior to open / close
    this.adjustDrawerWidth();

    // actually open / close the drawer
    const $drawer = $('.mod-playlist-drawer');
    if (this.isDrawerOpen) {
      $drawer.addClass('open');
    } else {
      $drawer.removeClass('open');
    }
  }

  setHamburgerOpen (isOpen) {
    if (isOpen === this.isHamburgerOpen) {
      return;
    }

    this.isHamburgerOpen = isOpen;

    const $nav = $('.site-nav');

    // open / close the nav
    if (this.isHamburgerOpen) {
      $nav.addClass('open');
    } else {
      $nav.removeClass('open');
    }

    // open / close the category dropdown
    const dimensions = main.determineDimensions();
    this.setCategoriesDropdownOpen(dimensions.breakpoint === MOBILE && this.isHamburgerOpen);
  }

  setCategoriesDropdownOpen (isOpen) {
    if (isOpen === this.isCategoriesDropdownOpen) {
      return;
    }

    this.isCategoriesDropdownOpen = isOpen;

    const $toggle = $('.site-nav__collections-item');
    const $dropdown = $('.mod-videos__categories.as-dropdown');
    if (this.isCategoriesDropdownOpen) {
      $toggle.addClass('open');
      $dropdown.show();
    } else {
      $toggle.removeClass('open');
      $dropdown.hide();
    }
  }

  adjustDrawerWidth () {
    $('.mod-playlist-drawer__container').width(window.innerWidth);
  }

  submitSearch () {
    const searchTerm = $('#site-search-input').val();
    if (!searchTerm || searchTerm.length === 0) {
      return;
    }

    Cookies.set('BCDISCOVERY_SQUERY', searchTerm);

    document.location.href = utils.urlWithDevice(window.baseUrl + '/search?q=' + encodeURIComponent(searchTerm));
  }
}

const main = new Main();
const categories = new Categories();
const playlist = new Playlist();
const nav = new Nav();

$(document).ready(function () {
  nav.init();
  categories.init();
  playlist.init();
});
const BCDISCOVERY_onWindowResize = function () {
  main.adjustRelatedLinks();
  nav.adjustDrawerWidth();
  playlist.adjustSiteWrapperHeight();
  if (main.determineDimensions().breakpoint !== MOBILE) {
    nav.setDrawerOpen(false);
  }
};
const BCDISCOVERY_onWindowScroll = function () {
  playlist.adjustOverscroll();
};
$(window).resize(function () {
  BCDISCOVERY_onWindowResize();
});
$(window).scroll(function () {
  BCDISCOVERY_onWindowScroll();
});
setTimeout(function () {
  BCDISCOVERY_onWindowResize();
  BCDISCOVERY_onWindowScroll();
}, 50);
